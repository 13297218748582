import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import ApiService from "@/core/services/ApiService";
import router from "@/router";

@Module
export default class SettingsModule extends VuexModule {
  settings = {
    address: "",
    city: "",
    fax: "",
    name: "",
    phone: "",
    repName: "",
    state: "",
    url: "",
    zip: "",
    logo: "",
    roll_type_real: "",
    enableTerminal: false,
    is_enabled: null,
  };
  urls = [];
  permit_departments = [];
  maintenance: { is_maintenance: boolean; message: string } | null = null;

  get isMaintenance(): boolean {
    if (!this.maintenance) {
      return false;
    }
    return (
      "is_maintenance" in this.maintenance && this.maintenance.is_maintenance
    );
  }
  get getMaintenanceMessage(): null | string {
    if (!this.maintenance) {
      return null;
    }
    if (
      "message" in this.maintenance &&
      this.maintenance.message &&
      this.maintenance.message.length > 0
    ) {
      return this.maintenance.message;
    }
    return null;
  }
  get adminSettings(): object {
    return this.settings;
  }
  get iFrameUrlsArray() {
    return this.urls;
  }
  @Mutation
  [Mutations.SET_IFRAME_URLS](data) {
    this.urls = data;
  }
  get permitDepartmentsArray() {
    return this.permit_departments;
  }
  @Mutation
  [Mutations.SET_PERMIT_DEPARTMENTS_ARRAY](data) {
    this.permit_departments = data;
  }

  @Mutation
  [Mutations.GET_ADMIN_SETTINGS]() {
    const methodUrl = `api/merchant-info`;
    ApiService.get(methodUrl)
      .then((response) => {
        this.settings = response.data.data;
        if (this.settings.is_enabled === 0) {
          router.push("404");
        }

        if ("maintenance" in response.data) {
          this.maintenance = response.data.maintenance;
        }
        if (window.location.hostname.split(".").length > 2) {
          document.title = `${this.settings.name} - ${document.title}`;
        } else {
          if (document.title.indexOf("ReCo Anywhere") !== -1) {
            document.title = `ReCo Anywhere | ${document.title}`;
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
  @Action
  [Actions.GET_IFRAME_URLS]() {
    return ApiService.get("api/merchant/iframe-urls")
      .then((response) => {
        this.context.commit(Mutations.SET_IFRAME_URLS, response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }
  @Action
  [Actions.GET_PERMIT_DEPARTMENTS]() {
    return ApiService.get("api/get-permit-departments")
      .then((response) => {
        this.context.commit(
          Mutations.SET_PERMIT_DEPARTMENTS_ARRAY,
          response.data.data,
        );
      })
      .catch((error) => {
        console.log(error);
      });
  }
}
